<template>
  <div class="mainPartInfo newSeeds">
    <!-- <div class="headInfo">
      <el-button type="success" @click="addDeviceCase">添加内容</el-button>
    </div> -->
    <div class="bodyInfo">
      <el-table
        :data="tableData"
        style="width: 100%"
        empty-text="暂无数据"
        ref="filterTable"
        :default-sort="{ prop: 'baseName', order: 'descending' }"
      >
        <el-table-column prop="id" label="ID" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="name" label="基地名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="address" label="基地地址" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="enterpriseName" label="基地面积" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="areaInfo" label="基地信息" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column prop="cropsName" label="作物类型"></el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="colBtns" @click="getInfo(scope.row)">详情</span>
            <span class="colBtns" @click="editDeviceCase(scope.row)">编辑</span>
            <!-- <span class="colBtns" @click="delCase(scope.row.id)">删除</span> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pageBox">
        <el-pagination
          background
          layout="sizes,prev, pager, next, jumper, total, slot"
          :total="dataTotal"
          :current-page="pageNow"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          @size-change="siceChange"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 新增 编辑 -->
    <el-dialog
      :title="eachTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="formContainter">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="基地信息" prop="areaInfo">
            <el-input type="textarea" v-model="ruleForm.areaInfo"></el-input>
          </el-form-item>
          <el-form-item label="上传图片" prop="messageImg">
            <div class="dialog-upload">
              <el-upload
                action="/imgUpdata/"
                ref="uploadRecord"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                accept=".png,.jpg">
                <img v-if="imgSrc" :src="imgSrc" class="avatar" style="width: 20vh; height: 20vh;">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确认</el-button
            >
            <el-button @click="resetForm()">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog
      title="详情"
      :visible.sync="basicInfo"
      width="30%"
      :before-close="handleCloseInfo"
    >
      <div class="basicBox">
        <div class="leftInfo">
          <div class="formContainter">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="基地名称">
                <el-input v-model="ruleForm.name" disabled></el-input>
              </el-form-item>
              <el-form-item label="基地地址">
                <el-input v-model="ruleForm.address" disabled></el-input>
              </el-form-item>
              <el-form-item label="基地面积">
                <el-input v-model="ruleForm.enterpriseName" disabled></el-input>
              </el-form-item>
              <!-- <el-form-item label="作物类型" prop="cropsName">
                <el-input v-model="ruleForm.cropsName" disabled></el-input>
              </el-form-item> -->
              <el-form-item label="负责人">
                <el-input disabled v-model="ruleForm.linkMan"></el-input>
              </el-form-item>
              <el-form-item label="联系电话">
                <el-input disabled v-model="ruleForm.phoneNum"></el-input>
              </el-form-item>
              <el-form-item label="创建日期">
                <el-date-picker
                  disabled
                  v-model="ruleForm.createTime"
                  type="datetime"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd hh:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <!-- <div>
            <h4 class="titleInfo">投入品数量:</h4>
            <div class="echarts1" ref="echarts1">暂无</div>
          </div> -->
        </div>
        <!-- <div class="rightInfo">
          <div>
            <h4 class="titleInfo">农事操作数据:</h4>
            <div class="echarts2" ref="echarts2"></div>
          </div>
          <div class="tableBox">
            <h4 class="titleInfo">扫码数据统计</h4>
            <table>
              <tr>
                <th>账号</th>
                <th>用户名</th>
                <th>扫码时间</th>
                <th>扫码次数</th>
              </tr>
              <tr v-for="(item, index) in recordList" :key="index">
                <td>{{ item.username }}</td>
                <td>{{ item.realname }}</td>
                <td>{{ item.createTime }}</td>
                <td>{{ item.serial }}</td>
              </tr>
            </table>
          </div>
        </div> -->
      </div>
      <!-- <el-button type="primary" @click="basicInfo = false">确认</el-button> -->
    </el-dialog>
  </div>
</template>

<script>
import setMap from "../../../components/setMap.vue";
import initMarkerMap from "../../../components/initMarkerMap.vue";
export default {
  components: { setMap, initMarkerMap },
  data() {
    return {
      recordList: [],
      ids: 0,
      // 曲线时间选择器
      value1: "",
      basicInfo: false,
      eachTitle: "新增",
      dialogVisible: false,
      search: "",
      // 头部搜索标志
      isShow: false,
      // 当前页
      pageNow: 1,
      dataTotal: 0,
      imgSrc: '',
      // 当前页数
      pageSize: 10,
      tableData: [],
      // 表单数据
      ruleForm: {
        id: 0,
        baseName: "",
        baseLoc: "",
        baseArea: "",
        phone: "",
        username: "",
        cropsName: "",
      },
      rules: {
        baseName: [
          { required: true, message: "请输入基地名称", trigger: "change" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        baseLoc: [
          { required: true, message: "请选择基地地址", trigger: "change" },
        ],
        baseArea: [
          {
            required: true,
            message: "请输入基地面积",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "请填写联系电话",
            trigger: "change",
          },
          {
            validator: function (rule, value, callback) {
              if (/^1[34578]\d{9}$/.test(value) == false) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        username: [
          {
            required: true,
            message: "请填写负责人",
            trigger: "change",
          },
        ],
        cropsName: [
          { required: true, message: "请填写作物类型", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    // 获取扫码数据统计
    async getFarmAll(id) {
      this.loading = true;
      const { data } = await this.$get("/factory/getScanData", {
        id: id,
      });
      if (data.datas && data.datas[0]) {
        this.recordList = data.datas;
      } else {
        this.recordList = [
          {
            id: 0,
            enterpriseId: "暂无",
            username: "暂无",
            createTime: "暂无",
            realname: "暂无",
            serial: "暂无",
          },
        ];
      }
      // if(data.surplus)
      // this.lineCanvased1(xData,yData);
    },
    // 获取投入品使用数量
    async getFarmUse(id) {
      this.loading = true;
      const { data } = await this.$get("/factory/getInputOut", {
        id: id,
      });
      let seriesArr = [];
      let countss = 0;
      if(data.data.count){
       countss = data.data.count;
       }
      if (data.data.list && data.data.list[0]) {
        data.data.list.forEach((val) => {
          seriesArr.push({
            name: val.inputsName || "",
            value: val.count || 0,
          });
        });
      } else {
        seriesArr = [
          {
            name: "暂无",
            value: 0,
          },
        ];
      }
      this.lineCanvased1(seriesArr, countss);

      // if(data.surplus)
      // this.lineCanvased1(xData,yData);
    },
    // 获取农事操作数据
    async getFarmRecord(id) {
      const {
        data: { data },
      } = await this.$get("/factory/getTaskById", {
        id: id,
      });
      let xData = [];
      let yData = [];
      xData = data.date;
      yData = data.list;
      if (!data.date && !data.date[0]) {
        xData = [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ];
      }
      if (!data.list && !data.list[0]) {
        yData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      }
      this.lineCanvased2(xData, yData);
    },
    // 获取列表
    async getList() {
      this.loading = true;
      const { data } = await this.$get("/enterpriseManage/querryLandPlot", {
        page: this.pageNow,
        size: this.pageSize,
        id: "",
        name: "",
      });
      this.tableData = data.datas;
      this.dataTotal = data.count;
    },
    // 获取曲线图
    lineCanvased1(seriesArr, countss) {
      this.$echarts.init(this.$refs.echarts1).dispose();
      var scaleData = seriesArr;
      var colorList = [
        "#07ca88",
        "orange",
        "#0785d9",
        "#CCFF33",
        "#CCCCFF",
        "#CC99FF",
        "#CC6699",
        "#CC6600",
        "#66CC00",
        "#669999",
        "#3300CC",
        "#00FFFF",
      ];
      const mychart = this.$echarts.init(this.$refs.echarts1);
      let option = {
        legend: {
          show: false,
          bottom: 0,
        },
        grid: {
          top: 10,
        },
        color: colorList,
        title: {
          text: "总数" + countss, //主标题文本
          subtext: "", //副标题文本
          left: "center",
          top: "45%",
          textStyle: {
            fontSize: 13,
            color: "#818181",
            align: "center",
          },
          subtextStyle: {
            fontFamily: "微软雅黑",
            fontSize: 14,
            color: "#181818",
          },
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            hoverAnimation: false,
            type: "pie",
            center: ["50%", "50%"],
            radius: ["70%", "50%"],
            clockwise: true,
            avoidLabelOverlap: true,
            hoverOffset: 14,
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            // label: {
            //   show: false,
            //   position: "outside",
            //   formatter: "{a|{b}：{d}%}\n{hr|}",
            // },
            label: {
              show: true,
              position: "outside",
              // formatter: "{a|{b}：{d}%}\n{hr|}",
              normal: {
                formatter: "{a|{b}}\n{hr|}",
                padding: [0, 0, -15, 0],
                width: 5,
                rich: {
                  icon: {
                    fontSize: 14,
                  },
                  name: {
                    fontSize: 14,
                    padding: [0, 4, 0, 4],
                    color: "#666666",
                  },
                  value: {
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "#333333",
                  },
                },
              },
            },
            labelLine: {
              normal: {
                length: 15,
                length2: 20,
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: scaleData,
          },
        ],
      };
      mychart.setOption(option);
    },
    // 获取曲线图
    lineCanvased2(xData, yData) {
      this.$echarts.init(this.$refs.echarts2).dispose();

      const mychart = this.$echarts.init(this.$refs.echarts2);
      let option = {
        textStyle: {
          fontSize: 0,
        },
        grid: {
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
          formatter: "{b} : {c}",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: {
          nameTextStyle: {
            color: "#c0c3cd",
            padding: [0, 0, -10, 0],
            fontSize: 14,
          },
          axisLabel: {
            color: "#8BA0C4",
            fontWeight: 400,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontSize: 14,
          },
          axisTick: {
            lineStyle: {
              color: "#0B1535",
              width: 1,
            },
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#384267",
              width: 1,
              type: "dashed",
            },
            show: true,
          },
          data: xData,
          type: "category",
        },
        yAxis: {
          type: "value",
          nameTextStyle: {
            color: "#c0c3cd",
            padding: [0, 0, -10, 0],
            fontSize: 14,
          },
          axisLabel: {
            margin: 20,
            color: "#8BA0C4",
            fontWeight: 400,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontSize: 14,
          },
          axisTick: {
            lineStyle: {
              color: "#384267",
              width: 1,
            },
            show: true,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#384267",
              type: "line",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
              width: 1,
              type: "line",
            },
            show: false,
          },
        },
        series: [
          {
            data: yData,
            type: "bar",
            barMaxWidth: "auto",
            barWidth: 16,
            itemStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: "linear",
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(61, 212, 214, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(16, 98, 255, 0.3)",
                  },
                ],
              },
            },
            label: {
              show: true,
              position: "top",
              distance: 10,
              color: "#fff",
              position: [30, 10],
            },
          },
          {
            data: [0, 0, 0],
            type: "pictorialBar",
            barMaxWidth: "0",
            symbol: "diamond",
            symbolOffset: [0, 0],
            symbolSize: [30, 15],
          },
        ],
      };
      mychart.setOption(option);
    },
    // 详情抽屉关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleCloseInfo(done) {
      done();
    },
    handleAvatarSuccess(res){
      this.imgSrc = 'https://public.half-half.cn/' + res.data.fileName;
    },
    // 详情
    getInfo(val) {
      this.ruleForm = JSON.parse(JSON.stringify(val));
      this.basicInfo = true;
      this.$nextTick(() => {
        // this.lineCanvased1();
        // this.lineCanvased2();
        this.getFarmRecord(val.id);
        this.getFarmAll(val.id);
        this.getFarmUse(val.id);
      });
    },
    // 获取地图组件数据
    getDatas(e) {
      this.ruleForm.baseLoc = e;
    },
    // 表单提交事件
    submitForm(formName) {
      let data = this.ruleForm;
      let param = {
        name: data.name,
        plotArea: data.plotArea,
        enterpriseName: data.enterpriseName,
        enterpriseId: data.enterpriseId,
        baseName: data.baseName,
        baseId: data.baseId,
        address: data.address,
        areaImgs: this.imgSrc,
        areaInfo: data.areaInfo,
        phoneNum: data.phoneNum,
        linkMan: data.linkMan,
        email: data.email,
        centerPos: data.centerPos,
        polygonWkt: data.polygonWkt,
        landPlotId: data.id
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/enterpriseManage/updateLandPlot", param).then((res) => {
            if (res.data.state == "success") {
              this.pageNow = 1;
              this.getList();
              this.$message.success("操作成功");
              this.dialogVisible = false;
            } else {
              this.$message.error(res.data.msg);
              this.dialogVisible = false;
            }
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    // 表单重置事件
    resetForm(formName) {
      this.dialogVisible = false;
      // this.$refs[formName].resetFields();
    },
    // 新增弹窗关闭事件
    handleClose(done) {
      // 销毁地图
      done();
    },
    // 删除实例
    delCase(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/factory/delFactory", { id: id }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 编辑事件
    editDeviceCase(val) {
      let _this = this;
      setTimeout(() => {
        _this.$refs["ruleForm"].clearValidate();
      }, 30);
      this.ids = val.id;
      this.imgSrc = val.areaImgs;
      this.ruleForm = JSON.parse(JSON.stringify(val));
      this.eachTitle = "编辑";
      this.dialogVisible = true;
    },
    // 添加事件
    addDeviceCase() {
      let _this = this;
      setTimeout(() => {
        _this.$refs["ruleForm"].clearValidate();
      }, 30);
      for (var key in this.ruleForm) {
        this.ruleForm[key] = null;
      }
      this.ids = 0;
      this.eachTitle = "新增";
      this.dialogVisible = true;
      // this.$refs[this.ruleForm].resetFields();
      // this.$refs.child.disposeMap();
    },
    // 当前页数
    siceChange(val) {
      this.pageSize = val;
      this.pageNow = 1;
      this.getList();
    },
    // 当前页
    currentChange(val) {
      this.pageNow = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../style/backTable.less";
.newSeeds {
  padding: 4vh 2vw !important;
  box-sizing: border-box;
}
.tableBox {
  width: 100%;
}
.titleInfo {
  margin-bottom: 1vh;
}
.basicBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.leftInfo {
  width: 100%;
}
.rightInfo {
  width: 55%;
}
.rightInfo > div {
  width: 100%;
  height: 50%;
}
.rightInfo > div table {
  width: 100%;
  word-break: break-all;
  border-collapse: collapse; //合并表格边框
  border-spacing: 0; //表格边框间距
  border: none;
  max-height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.rightInfo > div table::-webkit-scrollbar {
  display: none;
}
.rightInfo > div table tr td,
.rightInfo > div table tr th {
  text-align: center;
  border: none;
  // padding: 1.5%;
  color: #838383;
  font-size: 1.4vh;
  line-height: 1.5em;
  // width: 40%;
  word-break: break-all;
}

.echarts2 {
  height: 100%;
}
.echarts1 {
  width: 100%;
  height: 20vh;
}
// 整体和表格样式
.mainPartInfo {
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 2vh 2vw;
  box-sizing: border-box;
}
.mainPartInfo .headInfo {
  margin-bottom: 2vh;
}
.mainPartInfo .bodyInfo {
  max-height: 95%;
}
.mainPartInfo /deep/ .el-button--success {
//   background-color: #07ca88;
//   border-color: #07ca88;
 background-color: #409eff;
  border-color: #409eff;
}
.mainPartInfo /deep/ .el-button {
  padding: 1vh 1vw;
}
.mainPartInfo .colBtns {
  color: #07ca88;
  margin-right: 0.5vw;
  cursor: pointer;
}
.mainPartInfo /deep/ .el-table {
  max-height: 70vh;
  overflow-y: scroll;
}
.mainPartInfo /deep/ .el-table th {
  background: #fafafa;
  box-shadow: 1px 1px 2px #fafafa;
}
.mainPartInfo /deep/ .el-table th > .cell:nth-of-type(-n + 1) {
  border-right: 1px solid #ebeef5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainPartInfo .pageBox {
  margin-top: 2vh;
  text-align: center;
}
.mainPartInfo /deep/ .el-table__column-filter-trigger {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  padding: 0.5vh 0.5vw;
  line-height: 100%;
  float: right;
  background: url("../../../assets/twoBack/device3.png") no-repeat center/100%
    100% !important;
}
.mainPartInfo /deep/ .el-icon-arrow-down:before {
  content: "" !important;
  background: url("../../../assets/twoBack/device3.png") no-repeat center/100%
    100% !important;
}
.mainPartInfo /deep/ .el-table .caret-wrapper {
  float: right;
}
.mainPartInfo .headStyles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.mainPartInfo .headStyles p {
  width: 50%;
}

// 弹窗样式
.mainPartInfo /deep/ .el-input__inner:hover {
  border-color: #07ca88;
}
.mainPartInfo /deep/ .el-input__inner:focus {
  border-color: #07ca88;
}

.mainPartInfo .mapBox {
  width: 100%;
  height: 30vh;
  margin-bottom: 2vh;
  border-radius: 30px;
}
.watchData {
  height: 20vh;
  width: 100%;
  border-bottom: 1px solid #dcdfe6;
  overflow: hidden;
}
.watchData h4 {
  font-size: 1.6vh;
  line-height: 1.5em;
  margin: 1vh 0;
}
.watchData ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 80%;
  overflow-y: scroll;
}
.watchData ul::-webkit-scrollbar {
  display: none;
}
.watchData ul li {
  width: 50%;
  line-height: 1.5em;
  font-size: 1.4vh;
}
.watchData .imgList li {
  width: 22%;
}
.watchData .imgList li img {
  width: 100%;
}
.watchData ul li span {
  display: inline-block;
  margin-left: 0.5vw;
}
#lineCanvas {
  width: 100%;
  height: 20vh;
}

.pickerBox {
  width: 100%;
  margin-bottom: 2vh;
}
</style>>

<style>
  .el-icon-close:before {
    content: "\e6db";
    color: #fff;
  }
</style>